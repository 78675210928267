import React from 'react';
import {Link} from "react-router-dom";

interface WrapperProps {
    children: React.ReactNode;
}

const Wrapper = ({children}: WrapperProps) => {

    return (
        <div>
            <div className="w-full h-full flex items-center justify-center">
                <div className="w-full border dark:border-gray-800 flex items-center justify-between shadow-lg">
                    <div className="p-4">
                        <Link to="/" className="p-2 text-xl font-bold">Coffee Club</Link>
                    </div>
                    <div className="flex p-2 text-sm font-semibold">
                        <Link to="/coffees" className="p-2">Buy Coffees</Link>
                        <Link to="/api-keys" className="p-2">⚙️</Link>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100 p-1">
                {children}
            </div>
        </div>
    );
};

export default Wrapper;