import {useState} from "react";

export function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
    // Get the initial value from local storage or use the provided initial value
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });

    // Update the local storage value and the state value
    const setValue = (value: T) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error(error);
        }
    };

    return [storedValue, setValue];
}

export interface ApiKeys {
    clientId: string;
    clientSecret: string;
    baseUrl: string
}

export type SetApiKeys = (apiKeys: ApiKeys) => void;
export type UseApiKeys = [ apiKeys: ApiKeys | undefined, setApiKeys: SetApiKeys ]
export const useApiKeys = (): UseApiKeys => {
    const [apiKeys, setApiKeys] = useLocalStorage<ApiKeys | undefined>("apiKeys", undefined);

    if(apiKeys !== undefined) {
        apiKeys.clientId = apiKeys.clientId.trim()
        apiKeys.clientSecret = apiKeys.clientSecret.trim()
        apiKeys.baseUrl = apiKeys.baseUrl.trim()
    }
    return [apiKeys, setApiKeys]
}