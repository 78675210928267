export const Customers = [
    {"alias": "adam.strawson@sequencehq.com"},
    {"alias": "alen.mujezinovic@sequencehq.com"},
    {"alias": "chris.bond@sequencehq.com"},
    {"alias": "dmitri.grabov@sequencehq.com"},
    {"alias": "enda.cahill@sequencehq.com"},
    {"alias": "garry.wilson@sequencehq.com"},
    {"alias": "katherine.smith@sequencehq.com"},
    {"alias": "keith.grose@sequencehq.com"},
    {"alias": "leah.sharma@sequencehq.com"},
    {"alias": "lewis.blackwood@sequencehq.com"},
    {"alias": "matthew.collier@sequencehq.com"},
    {"alias": "merlin.kafka@sequencehq.com"},
    {"alias": "mohamed.sharaf@sequencehq.com"},
    {"alias": "muhaned.rashed@sequencehq.com"},
    {"alias": "nicolas.badaro@sequencehq.com"},
    {"alias": "rafal.szalanski@sequencehq.com"},
    {"alias": "raniero.russo@sequencehq.com"},
    {"alias": "rebecca.fulton@sequencehq.com"},
    {"alias": "riya.grover@sequencehq.com"},
    {"alias": "rosie.allot@sequencehq.com"},
    {"alias": "stefano.romano@sequencehq.com"},
    {"alias": "tania.perroud@sequencehq.com"},
]

export default Customers;