import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import Wrapper from './Wrapper';
import {CoffeeForm} from './CoffeeForm';
import {BrowserRouter, redirect, Route, Routes, useNavigate} from "react-router-dom"
import {ApiKeyForm} from "./ApiKeyForm";
import {useApiKeys} from "./UseApiKeys";

const Home = () => {
    const [apiKeys, _] = useApiKeys()
    const navigate = useNavigate()
    console.log("Home", apiKeys)

    useEffect(() => {
        if (apiKeys === undefined) {
            navigate("/api-keys")
        } else {
            navigate("/coffees")
        }
    }, [apiKeys])


    return <div>Loading</div>
}

function App() {
    return (<BrowserRouter>
            <Wrapper>
                <Routes>
                    <Route path="/api-keys" element={<ApiKeyForm/>}/>
                    <Route path="/coffees" element={<CoffeeForm/>}/>
                    <Route path="/" element={<Home/>}/>
                </Routes>
            </Wrapper>
        </BrowserRouter>
    )
}

export default App;
