import Wrapper from "./Wrapper"
import Customers from "./Customers"
import React, {useState} from "react";
import axios from "axios";
import {ApiKeys, useApiKeys} from "./UseApiKeys";

export const apiUrl = (keys: ApiKeys | undefined, path: string) => `${keys?.baseUrl}${path}`


interface FormProps {
    onSubmit: (data: FormData) => void;
}

interface FormData {
    cost: string;
}


const onSubmit = async (apiKeys: ApiKeys | undefined, alias: string, formData: FormData) => {
    return await axios.post(apiUrl(apiKeys,"/api/usage-events"), {
        "customerAlias": alias,
        "eventType": "coffee",
        "eventProperties": {
            "cost": parseFloat(formData.cost)
        }
    }, {
        headers: {
            Authorization: "Basic " + btoa(`${apiKeys?.clientId}:${apiKeys?.clientSecret}`),
        }
    })
}

const CustomerCoffeeForm = ({customer}: { customer: { alias: string } }) => {
    const [apiKeys, _] = useApiKeys()
    const [formData, setFormData] = useState<FormData>({cost: "0"});
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const fullName = customer.alias.split("@")[0]
    const [firstName, lastName] = fullName.split(".")
        .map(it => it.charAt(0).toUpperCase() + it.slice(1))

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({...prevFormData, [name]: value}));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            setIsSubmitting(true);
            await onSubmit(apiKeys, customer.alias, formData);
            setIsSubmitted(true);
        } catch (error) {
            console.error(error);
            setError(JSON.stringify(error, null, 2));
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-xs mb-4 mr-4 border-2 p-2">
            <div className="mb-4"><h2 className="text-xl font-bold">{firstName} {lastName}</h2></div>
            <div className="mb-4">
                <label htmlFor="number-input" className="block font-medium text-gray-700">Cost:</label>
                <input
                    id="number-input"
                    type="number"
                    name="cost"
                    step="0.01"
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
            {error ? (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative " role="alert">
                    <pre style={{overflow: "scroll"}}>
                        <code>{error}</code>
                    </pre>
                </div>
            ) : null}
            {isSubmitted ? (
                    <div>
                        <button onClick={() => setIsSubmitted(false)} className="bg-green-100 border border-green-400 text-green-700 py-2 px-4 rounded relative p-0 min-w-full" role="alert" type="button">Submitted</button>
                    </div>
            ) : isSubmitting ? (
                <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-2 rounded relative"
                     role="alert">
                    Submitting
                </div>
            ) : (
                <button type="submit"
                        className="bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 p-0 min-w-full">
                    Buy {firstName} a Coffee
                </button>
            )}
            </div>
        </form>
    );
}

export const CoffeeForm = () => {
    console.log("Coffee Form")
    const customerForms = Customers.map((customer, idx) =>
        <div key={idx} className="mb-8">
            <CustomerCoffeeForm customer={customer}/>
        </div>
    )

    return (
        <div className="m-3">
            <div className="mb-8"><h1 className="text-2xl font-bold">Buy coffees</h1></div>
            <div className="mb-8">
                <p className="mt-2 text-sm text-gray-500">
                    Enter the cost of every coffee you've bought and hit submit to send a usage event to Sequence.
                </p>
            </div>
            <div className="flex flex-wrap justify-center">
                {customerForms}
            </div>
        </div>
    )
}