import React, {useEffect, useState} from "react";
import Wrapper from "./Wrapper";
import {ApiKeys, SetApiKeys, useApiKeys} from "./UseApiKeys";

export interface ApiKeyFormProps {
    apiKeys: ApiKeys | undefined;
    setApiKey: SetApiKeys;
}

export const ApiKeyForm = () => {
    console.log("API Key Form")

    const [apiKeys, setApiKeys] = useApiKeys();
    const [baseUrl, setBaseUrl] = useState(apiKeys?.baseUrl || 'https://eu.sequencehq.com');
    const [clientId, setClientId] = useState(apiKeys?.clientId || '');
    const [clientSecret, setClientSecret] = useState(apiKeys?.clientSecret || '');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setApiKeys({baseUrl: baseUrl.trim(), clientId: clientId.trim(), clientSecret: clientSecret.trim()});
        setIsSubmitted(true);
    };

    useEffect(() => {
        setTimeout(() => {
            setIsSubmitted(false);
        }, 5000)
    }, [isSubmitted]);

    return (
        <form onSubmit={handleSubmit} className="max-w-md">
            <div ><h1 className="text-2xl font-bold mb-4">API Keys</h1></div>
            <div className="mb-8 max-w-md">
                <p className="mt-2 text-sm text-gray-500">
                    Enter your Sequence API keys below to get started. Once you have entered the API keys,
                    you can start submitting usage events when you buy coffees for your colleagues.
                </p>
            </div>
            <div className="mb-8">
                <label htmlFor="base-url-input" className="block text-gray-700 font-bold mb-2">API Base URL</label>
                <input
                    id="base-url-input"
                    type="url"
                    value={baseUrl}
                    onChange={(event) => setBaseUrl(event.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <p className="mt-2 text-sm text-gray-500">
                    This is the base URL for the API.
                </p>
            </div>
            <div className="mb-8">
                <label htmlFor="client-id-input" className="block text-gray-700 font-bold mb-2">Client ID:</label>
                <input
                    id="client-id-input"
                    type="text"
                    value={clientId}
                    onChange={(event) => setClientId(event.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <p className="mt-2 text-sm text-gray-500">
                    This is the client ID for your Sequence account.
                </p>
            </div>
            <div className="mb-8">
                <label htmlFor="client-secret-input" className="block text-gray-700 font-bold mb-2">
                    Client Secret:
                </label>
                <input
                    id="client-secret-input"
                    type="password"
                    value={clientSecret}
                    onChange={(event) => setClientSecret(event.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <p className="mt-2 text-sm text-gray-500">
                    This is the client secret for your Sequence account. It is stored in your browser and never
                    sent to our servers.
                </p>
            </div>
            {isSubmitted && <div className="text-green-500 mb-6"><p>Saved!</p></div>}
            {!isSubmitted && (
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Save
                </button>
            )}
        </form>
    );
};